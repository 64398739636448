import React from "react";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import interpolate from "../utils/interpolate";
import LandesgartenschauIcon from "../img/events/landesgartenschau.svg";
import LandesgartenschauHeader from "../components/LandesgartenschauHeader";

const events = [
  // landesgartenschau;
  new Feature({
    from: "2024-05-15T00:00Z",
    until: "2024-10-06T23:59Z",
    geometry: new Point([4494554, 2210623]), // Heimstetten bahnhof
    style: (map) => () => {
      const zoom = map.getView().getZoom();
      const scale = interpolate(zoom, [
        [5, 0.2],
        [6.5, 0.3],
      ]);
      const displacementY = interpolate(zoom, [
        [5, 40],
        [8, 60],
      ]);
      return new Style({
        image: new Icon({
          src: LandesgartenschauIcon,
          scale,
          displacement: [0, displacementY],
          anchor: [0.5, 1],
        }),
      });
    },
    stationUic: 8002715,
    stationBanner: <LandesgartenschauHeader />,
    onClick: () => {
      window.open("https://kirchheim2024.de/", "_blank");
    },
  }),
];
export default events;
